import {KeyOf} from '@/misc/Parseable';

export function sortArray<Model>(
  arr: Model[],
  desc: boolean,
  attr: ((el: Model) => number | string) | KeyOf<Model, string | number | undefined>): Model[];
export function sortArray(arr: number[], desc: boolean): number[];
export function sortArray(arr: string[], desc: boolean): string[];

export function sortArray<Model>(
  arr: Model[],
  desc: boolean,
  attributeOrCallback?: ((el: Model) => number | string) | KeyOf<Model>,
): Model[] {
  const callback =
    typeof attributeOrCallback === 'function' ?
      attributeOrCallback :
      (el: Model) => (attributeOrCallback ? el[attributeOrCallback] : el);
  return arr.sort((a, b) =>
    desc ?
      callback(a) < callback(b) ? 1 : -1 :
      callback(a) > callback(b) ? 1 : -1);
}
